.app-btn {
    @include custom-button();
}

.rounded-dropdown {
    background: #ccc;
    border: 1px solid #ccc;
}

$rounded-dropdown-background: #ccc;
$rounded-dropdown-border: 1px solid #ccc;

@mixin rounded-dropdown-mixin(
    $rounded-dropdown-background: $rounded-dropdown-background,
    $rounded-dropdown-border: $rounded-dropdown-border
) {
    background: $rounded-dropdown-background;
    border: $rounded-dropdown-border;
}
