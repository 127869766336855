.x-cls-measurements-widget-widget {
    opacity: 1;
    display: flex;
    flex: 1;

    & > div,
    & > div > div {
        flex: 1;
    }
}

.x-cls-measurements-widget-widget-header {
    opacity: 1;
}

.x-cls-measurements-widget-widget-body {
    opacity: 1;
}

.x-cls-measurement-widget {
    width: 100%;
    opacity: 1;
    padding: 10px;
    display: flex;
    position: relative;
}
