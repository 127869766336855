@import 'scss/main.scss';

.x-cls-plant-fault-by-stud-type-spr-widget {
    opacity: 1;
    display: flex;
    flex: 1;

    & > div,
    & > div > div {
        flex: 1;
    }

    &.react-grid-item {
        // default widget background and border
        background-color: #333;
        border: 1px solid #bbb;
    }
}

.x-cls-plant-fault-by-stud-type-spr-widget-header {
    opacity: 1;
}

.x-cls-plant-fault-by-stud-type-spr-widget-body {
    opacity: 1;
}
