@import 'scss/main.scss';

.x-cls-probability-density-function-widget {
    opacity: 1;
    // height: 355px;
    width: 48%;
    margin-right: 1%;
    // flex: 1;

    & > div,
    & > div > div {
        flex: 1;
    }

    &.react-grid-item {
        // default widget background and border
        background-color: #333;
        border: 1px solid #bbb;
    }
}

.x-cls-probability-density-function-widget-header {
    opacity: 1;
}

.x-cls-probability-density-function-widget-body {
    opacity: 1;
}
