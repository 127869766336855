html {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    body {
        margin: 0;
        transition: background 0.5s ease-out;
        flex: 1;
        height: 100vh;
        width: 100vw;
        max-height: 100%;
        max-width: 100%;
        padding: 0;
        #root {
            flex: 1;
            display: flex;
            max-height: 100vh;
            max-width: 100vw;
            width: 100vw;
            height: 100vh;
            flex-direction: column;
        }
    }
}

.stanleyRow {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: 0px;
    margin-left: 0px;
}

.stanleyCol {
    display: flex;
    flex-flow: column nowrap;
}



// @import 'scss/main.scss';
// body {
//     margin: 0;
//     font-family: 'Open Sans', 'Frutiger Roman', 'Frutiger Bold';
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     padding: 0;
//     &.fontLoaded {
//         font-family: 'Open Sans', 'Frutiger Roman', 'Frutiger Bold';
//     }
// }

// code {
//     font-family: 'Frutiger Roman', 'Frutiger Bold', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
// }

// :root {
//     --blue: #007bff;
//     --indigo: #674eec;
//     --purple: #8445f7;
//     --pink: #ff4169;
//     --red: #c4183c;
//     --orange: #fb7906;
//     --yellow: #ffb400;
//     --green: #17c671;
//     --teal: #1adba2;
//     --cyan: #00b8d8;
//     --white: #fff;
//     --gray: #868e96;
//     --gray-dark: #343a40;
//     --primary: #007bff;
//     --secondary: #5a6169;
//     --success: #17c671;
//     --info: #00b8d8;
//     --warning: #ffb400;
//     --danger: #c4183c;
//     --light: #fbfbfb;
//     --dark: #212529;
//     --breakpoint-xs: 0;
//     --breakpoint-sm: 576px;
//     --breakpoint-md: 768px;
//     --breakpoint-lg: 992px;
//     --breakpoint-xl: 1200px;
//     --font-family: Open Sans;
// }

// /* total width */

// body::-webkit-scrollbar {
//     background-color: #12181d;
//     width: 16px;
// }

// /* background of the scrollbar except button or resizer */

// body::-webkit-scrollbar-track {
//     background-color: #12181d;
// }

// /* scrollbar itself */

// body::-webkit-scrollbar-thumb {
//     background-color: #000;
//     border-radius: 16px;
//     border: 4px solid #12181d;
// }

// /* set button(top and bottom of the scrollbar) */

// body::-webkit-scrollbar-button {
//     display: none;
// }

// body {
//     font-size: 1rem;
//     font-weight: 300;
//     font-family: Open Sans;
//     color: #5a6169;
//     //background-color: #12181d;
// }

// a {
//     color: #007bff;
//     text-decoration: none;
// }

// label {
//     color: #fff;
//     font-size: 15px;
//     font-weight: 500;
// }

// // a:hover {
// //     color: #0056b3;
// //     text-decoration: underline;
// // }

// .custom-control-inline {
//     display: inline-flex;
//     margin-right: 5rem;
//     color: #7b8085;
// }

// b,
// strong {
//     font-weight: 500;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//     margin-top: 0;
//     margin-bottom: 0.5rem;
// }

// .h1,
// .h2,
// .h3,
// .h4,
// .h5,
// .h6 {
//     display: block;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// .h1,
// .h2,
// .h3,
// .h4,
// .h5,
// .h6 {
//     margin-bottom: 0.75rem;
//     font-family: Open Sans;
//     font-weight: 400;
//     color: #fff;
// }

// span {
//     font-family: Open Sans;
// }

// h1,
// .h1 {
//     font-size: 3.052rem;
//     letter-spacing: -0.0625rem;
//     line-height: 3rem;
// }

// h2,
// .h2 {
//     font-size: 2.441rem;
//     letter-spacing: -0.0625rem;
//     line-height: 2.25rem;
// }

// h3,
// .h3 {
//     font-size: 1.953rem;
//     line-height: 2.25rem;
// }

// h4,
// .h4 {
//     font-size: 1.563rem;
//     line-height: 2rem;
// }

// h5,
// .h5 {
//     font-size: 1.25rem;
//     line-height: 1.5rem;
// }

// h6,
// .h6 {
//     font-size: 1rem;
//     line-height: 1.5rem;
// }

// .lead {
//     line-height: 1.875rem;
// }

// [role='listbox'] li {
//     color: #fff;
// }

// .display-1,
// .display-2,
// .display-3,
// .display-4 {
//     margin-bottom: 0.75rem;
// }

// .display-1 {
//     font-size: 7.451rem;
//     line-height: 1;
// }

// .display-2 {
//     font-size: 5.96rem;
//     line-height: 1;
// }

// .display-3 {
//     font-size: 4.768rem;
//     line-height: 1;
// }

// .display-4 {
//     font-size: 3.815rem;
//     line-height: 1;
// }

// .navbar {
//     background: transparent;
//     border-color: #181616d3;
//     background: #000000;
//     color: #ffb400;
//     margin-left: -30px;
//     margin-right: -28px;
//     height: 3.3rem;
// }

// .navbar .dropdown-menu-right {
//     left: auto;
//     margin-top: 0px !important;
//     background-color: #0e171c;
// }

// .navbar .px-3 {
//     padding-right: 1rem !important;
//     padding-bottom: 2.85rem !important;
//     padding-top: 1rem !important;
// }

// .navbar span {
//     color: #fff;
//     margin-right: 0.5rem;
// }

// .navbar .dropdown-toggle::after {
//     color: #fff;
// }

// .navbar i {
//     color: #fff;
//     margin-left: 10%;
//     padding: 17px 5px 5px 10px;
//     cursor: pointer;
// }

// #mySidebar {
//     width: 230px;
//     height: 100%;
//     background: #0e171c;
//     z-index: 3040;
//     margin-top: 46px;
//     position: fixed;
//     margin-left: -16px;
//     padding-left: 10px;
// }

// #mySidebar li {
//     margin-bottom: 5%;
//     list-style: none;
//     color: #3c4044;
//     margin-left: -25px;
// }

// #mySidebar i {
//     font-size: 13px;
//     margin-left: -5%;
//     margin-top: -19px;
//     color: #3c4044;
// }

// #mySidebar img {
//     margin-left: 2%;
//     max-width: 8rem;
//     position: fixed;
//     bottom: 20px;
// }

// .center {
//     align-items: center;
//     text-align: center;
//     justify-content: center;
// }

// .open {
//     margin-top: -33px;
//     margin-bottom: 0.5rem;
//     margin-left: 30%;
//     margin-top: -36px;
//     color: #ffd20a;
//     width: 20%;
// }

// .closesideNav {
//     margin-top: -34px;
//     margin-bottom: 0.5rem;
//     margin-left: 30%;
//     color: #ffd20a;
//     width: 20%;
// }

// .pageTitle {
//     margin-right: 30% !important;
//     margin-top: 1rem;
// }

// .pageTitle h4 {
//     margin-top: -5px;
//     color: #fcdc40;
//     margin-left: 10px;
//     font-size: 1.4rem;
// }

// .page-title {
//     padding-top: 5px;
//     color: #fcdc40;
// }

// .pageTitle h4::first-letter {
//     text-transform: uppercase;
// }

// .navbar .form-control {
//     display: block;
//     width: 100%;
//     height: calc(1.5em + 0.75rem + 2px);
//     padding: 0.375rem 0.75rem;
//     font-size: 1rem;
//     font-weight: 400;
//     line-height: 1.5;
//     color: #495057;
//     background-color: #1d272f;
//     background-clip: padding-box;
//     border: 0px solid #ced4da;
//     border-radius: 1.25rem;
//     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//     margin-top: 7px;
// }

// .navSidebar {
//     width: 250px;
// }

// .navSidebar .user-avatar {
//     max-width: 8rem;
//     margin-left: 5px;
// }

// .navigation--active a {
//     color: #fff !important;
//     font-size: 13px;
// }

// .navigation--active i {
//     color: #fcdc40 !important;
// }

// ul > li {
//     cursor: pointer;
// }

// .show {
//     display: block !important;
// }

// .hide {
//     display: none !important;
// }

// .vl {
//     border-left: 1px solid white;
//     height: 41px;
//     margin-left: 0px;
//     margin-top: 15px;
// }

// .navbar .input-group > .custom-select:not(:last-child),
// .input-group > .form-control:not(:last-child) {
//     border-radius: 20px;
// }

// .input-group-append {
//     z-index: 11111;
// }

// .form-control:focus {
//     outline: none !important;
//     box-shadow: 0 0 0 0rem !important;
// }

// .badge-warning {
//     position: relative;
//     top: -20px;
//     left: 12px;
// }

// .main-content > .main-content-container .container-fluid {
//     min-height: 100vh;
// }

// .main-content-container {
//     padding-bottom: 30px;
// }

// .ScrollbarsCustom-Thumb {
//     background-color: #000000 !important;
// }

// .px-4 {
//     padding-top: 1.5rem !important;
// }

// .main-content-container h4 {
//     font-size: 1rem;
//     line-height: 2rem;
// }

// #mySidebar a {
//     color: #3c4044;
//     font-size: 13px;
// }

// .btn {
//     box-shadow: none !important;
// }

// .projects-header {
//     font-size: 1rem;
//     line-height: 2rem;
//     margin-left: 0.9rem;
// }

// /* .custom-radio .custom-control-inline{
//   font-size: 13px !important;
//   color: #E0E0E0 !important;
// } */

// .custom-radio .custom-control-label {
//     color: #747474 !important;
//     font-size: 16px !important;
// }

// .custom-radio .custom-control-label::before {
//     background-color: #28333e !important;
//     border-radius: 2rem;
//     border: 3px solid #fff !important;
//     outline: none !important;
// }

// .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
//     background-color: #28333e !important;
//     border-radius: 2rem;
//     border: 3px solid #ffdb0a !important;
//     outline: none !important;
//     background-image: none;
// }

// .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
//     color: #000000 !important;
//     border-color: #ffdb0a !important;
//     background-color: #ffdb0a !important;
//     outline: none;
// }

// .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
//     background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"><path d="M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z" fill="%23000"/></svg>');
//     border-color: rgb(252, 245, 206) !important;
// }

// .ReactTable .rt-tbody {
//     text-align: left !important;
//     font-family: Open Sans !important;
// }

// /* .ReactTable .rt-tr-group {
//   height: 38px !important;
// } */

// .ReactTable .rt-tbody .rt-tr-group {
//     border: none !important;
// }

// .ReactTable .rt-thead .rt-tr {
//     text-align: left !important;
//     background-color: #0a0a0a !important;
//     font-family: Open Sans !important;
// }

// .ReactTable .rt-tr.-odd {
//     background-color: #1f2831 !important;
// }

// .ReactTable .rt-tr.-even {
//     background-color: #181f26 !important;
// }

// .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
//     background-color: #ffffff !important;
//     color: #0a0a0a !important;
// }

// .saveDraftIcon {
//     color: #ffffff;
//     margin-left: -10px;
//     margin-right: 10px;
// }

// .saveSubmitIcon {
//     color: #00a264;
//     margin-left: -4px;
//     margin-right: 6px;
// }

// .custHeaderTitle {
//     font-size: 24px !important;
//     font-weight: 500 !important;
//     font-family: Open Sans !important;
// }

// .custSubHeaderTitle {
//     font-size: 20px !important;
//     font-weight: 500 !important;
//     font-family: Open Sans !important;
// }

// .custLabelSize {
//     font-size: 18px !important;
//     font-weight: normal !important;
// }

// .custLabelBoldSize {
//     font-size: 18px !important;
//     font-weight: 500 !important;
// }

// .custSmallLabelSize {
//     font-size: 16px !important;
// }

// .custSmallTextSize {
//     font-size: 16px !important;
// }

// .react-dropdown-select-dropdown {
//     border: 1px solid #28333e !important;
//     font-size: 16px !important;
//     font-weight: 400;
//     margin-top: -2px !important;
// }

// .react-dropdown-select-item {
//     background-color: #28333e !important;
//     color: #fff !important;
//     border: 1px solid #28333e !important;
// }

// .react-dropdown-select-item:hover,
// .react-dropdown-select-item:focus {
//     background: #ffdb0a !important;
//     color: #000 !important;
//     border: 1px solid #28333e !important;
//     outline: none !important;
// }

// .react-dropdown-select-item-selected {
//     background: #ffdb0a !important;
//     color: #000 !important;
// }

// .search-gap {
//     display: inline-flex;
// }

// .loading-spinner-container {
//     background-color: #282c34;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     position: fixed;
//     top: 0;
//     bottom: 0;
//     right: 0;
//     left: 0;
// }

// /* ----------- Media Queies ----------- */

// @import url('assets/fonts/frutiger/style.css');
// @media (max-width: 575.98px) {
//     html {
//         font-size: 15px;
//     }
//     .content-height {
//         height: 400px !important;
//     }
// }

// @media (min-width: 576px) {
//     .modal-dialog {
//         max-width: 750px !important;
//         margin: 1.875rem auto;
//     }
//     .pop-up-row {
//         width: 750px !important;
//     }
//     .modal-body-height {
//         height: 50vh !important;
//     }
// }

// @media (max-width: 576px) {
//     .form-inline .form-check-input {
//         margin-right: 0.313rem;
//     }
//     .navbar-expand-sm .navbar-nav .nav-link {
//         padding-right: 0.625rem;
//         padding-left: 0.625rem;
//     }
//     .card-deck {
//         margin-right: -0.9375rem;
//         margin-left: -0.9375rem;
//     }
//     .card-deck .card {
//         margin-right: 0.9375rem;
//         margin-left: 0.9375rem;
//     }
//     .card-group {
//         box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
//             0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
//         border-radius: 0.625rem;
//     }
//     .card-group > .card {
//         box-shadow: none;
//     }
//     .card-group > .card:first-child {
//         border-top-right-radius: 0;
//         border-bottom-right-radius: 0;
//     }
//     .card-group > .card:last-child {
//         border-top-left-radius: 0;
//         border-bottom-left-radius: 0;
//     }
//     .card-group > .card:only-child {
//         border-radius: 0.625rem;
//     }
//     .card-group > .card:only-child .card-header,
//     .card-group > .card:only-child .card-img-top {
//         border-top-left-radius: 0.625rem;
//         border-top-right-radius: 0.625rem;
//     }
//     .card-group > .card:only-child .card-footer,
//     .card-group > .card:only-child .card-img-bottom {
//         border-bottom-right-radius: 0.625rem;
//         border-bottom-left-radius: 0.625rem;
//     }
//     .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
//         border-radius: 0;
//     }
//     .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer,
//     .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
//     .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
//     .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
//         border-radius: 0;
//     }
//     .card-columns {
//         -webkit-column-count: 3;
//         column-count: 3;
//         -webkit-column-gap: 1.25rem;
//         column-gap: 1.25rem;
//     }
//     .jumbotron {
//         padding: 4rem 2rem;
//     }
//     .modal-dialog {
//         max-width: 850px !important;
//         margin: 1.875rem auto;
//     }
//     .pop-up-row {
//         width: 850px !important;
//     }
//     .modal-body-height {
//         height: 10vh !important;
//     }
//     .modal-dialog-centered {
//         min-height: calc(100% - (1.875rem * 2));
//     }
//     .modal-content {
//         box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
//             0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
//     }
//     .modal-sm {
//         max-width: 500px;
//     }
//     .content-height {
//         height: 580px !important;
//     }
//     .weldarctime span {
//         font-size: 15px !important;
//     }
//     .parent {
//         font-size: 18px !important;
//     }
//     .radio-align {
//         margin-left: 20% !important;
//     }
//     .welding-spec th {
//         font-weight: 400 !important;
//         font-size: 12px !important;
//     }
//     .welding-spec td {
//         font-weight: 300 !important;
//         font-size: 10px !important;
//     }
// }

// @media (min-width: 992px) {
//     .modal-dialog {
//         max-width: 800px;
//         margin: 1.875rem auto;
//     }
//     .pop-up-row {
//         width: 800px;
//     }
//     .modal-body-height {
//         height: 55vh !important;
//     }
// }

// @media (max-width: 1024px) {
//     .page-title {
//         padding-left: 20px !important;
//     }
//     span {
//         font-size: 14px;
//     }
//     .search-gap {
//         display: inline-flex;
//         margin-left: -15px;
//     }
//     /* .designation {
//     font-size: 10px;
//     color: white;
//     position: fixed;
//     top: 4%;
//     right: 1%;
//   } */
//     .vl {
//         border-left: 1px solid white;
//         height: 30px;
//         margin-left: 10px;
//     }
//     .weldarctime span {
//         font-size: 12px !important;
//     }
//     .parent {
//         font-size: 16px !important;
//     }
//     .welding-spec .date-align {
//         font-size: 12px !important;
//         padding-left: 50% !important;
//     }
//     .radio-align {
//         margin-left: 20% !important;
//     }
//     .welding-spec th {
//         font-weight: 400 !important;
//         font-size: 12px !important;
//     }
//     .welding-spec td {
//         font-weight: 300 !important;
//         font-size: 11px !important;
//     }
//     .data-in-spec {
//         height: 165px !important;
//     }
// }

// @media (min-width: 1100px) {
//     .radio-align {
//         margin-left: 50% !important;
//     }
//     .modal-dialog {
//         max-width: 850px;
//         margin: 1.875rem auto;
//     }
//     .pop-up-row {
//         width: 850px;
//     }
//     .modal-body-height {
//         height: 60vh !important;
//     }
//     .welding-spec th {
//         font-weight: 400 !important;
//         font-size: 14px !important;
//     }
//     .welding-spec td {
//         font-weight: 300 !important;
//         font-size: 14px !important;
//     }
//     .data-in-spec {
//         height: 183px !important;
//     }
// }

// @media (min-width: 1366px) {
//     .radio-align {
//         margin-left: 50% !important;
//     }
//     .modal-dialog {
//         max-width: 1000px;
//         margin: 1.875rem auto;
//     }
//     .pop-up-row {
//         width: 1000px;
//     }
//     .modal-body-height {
//         height: 65vh !important;
//     }
//     .welding-spec th {
//         font-weight: 400 !important;
//         font-size: 13px !important;
//     }
//     .welding-spec td {
//         font-weight: 300 !important;
//         font-size: 12px !important;
//     }
//     .data-in-spec {
//         height: 190px !important;
//     }
// }

// @media (min-width: 1600px) {
//     body {
//         font-size: 1.5rem;
//     }
//     h5,
//     h6 {
//         font-size: 1.5rem !important;
//     }
//     .table th {
//         font-size: 1.2rem;
//     }
//     .report-tbl .table td {
//         font-size: 1.2rem;
//     }
//     .viewreport .modal-header span {
//         font-size: 1.8rem;
//         font-weight: 500;
//     }
//     .content-height {
//         height: 740px !important;
//     }
//     .modal-dialog {
//         max-width: 1200px;
//         margin: 1.875rem auto;
//     }
//     .pop-up-row {
//         width: 1200px;
//     }
//     .modal-body-height {
//         height: 70vh !important;
//     }
//     .radio-align {
//         margin-left: 50% !important;
//     }
//     .welding-spec th {
//         font-weight: 500 !important;
//         font-size: 16px !important;
//     }
//     .welding-spec td {
//         font-weight: 400 !important;
//         font-size: 16px !important;
//     }
//     .data-in-spec {
//         height: 210px !important;
//     }
// }

// @media (min-width: 1720px) {
//     body {
//         font-size: 1.5rem;
//     }
//     h5,
//     h6 {
//         font-size: 1.5rem !important;
//     }
//     .table th {
//         font-size: 1.2rem;
//     }
//     .report-tbl .table td {
//         font-size: 1.2rem;
//     }
//     .viewreport .modal-header span {
//         font-size: 1.8rem;
//         font-weight: 500;
//     }
//     .content-height {
//         height: 790px !important;
//     }
//     .modal-dialog {
//         max-width: 1250px;
//         margin: 1.875rem auto;
//     }
//     .pop-up-row {
//         width: 1250px;
//     }
//     .modal-body-height {
//         height: 75vh !important;
//     }
//     .welding-spec th {
//         font-weight: 500 !important;
//         font-size: 18px !important;
//     }
//     .welding-spec td {
//         font-weight: 400 !important;
//         font-size: 16px !important;
//     }
//     .data-in-spec {
//         height: 218px !important;
//     }
// }

// @media (min-width: 1920px) {
//     body {
//         font-size: 1.5rem;
//     }
//     h5,
//     h6 {
//         font-size: 1.5rem !important;
//     }
//     .table th {
//         font-size: 1.2rem;
//     }
//     .report-tbl .table td {
//         font-size: 1.2rem;
//     }
//     .viewreport .modal-header span {
//         font-size: 1.8rem;
//         font-weight: 500;
//     }
//     .content-height {
//         height: 900px !important;
//     }
//     .modal-dialog {
//         max-width: 1500px;
//         margin: 1.875rem auto;
//     }
//     .pop-up-row {
//         width: 1500px;
//     }
//     .modal-body-height {
//         height: 75vh !important;
//     }
//     .welding-spec th {
//         font-weight: 500 !important;
//         font-size: 18px !important;
//     }
//     .welding-spec td {
//         font-weight: 400 !important;
//         font-size: 16px !important;
//     }
//     .data-in-spec {
//         height: 230px !important;
//     }
// }

// :root {
//     --main-bg-color: #12181d;
//     --text-color: #212529;
//     --box-bg-color: #000;
//     --sidebar-bg-color: #f7f7f7;
//     --input-bg-color: #fff;
//     --border-color: #ececec;
//     --button-color: #3282b8;
// }

// body.dark {
//     --main-bg-color: #282c35;
//     --text-color: #fff;
//     --box-bg-color: #000;
//     --sidebar-bg-color: #444;
//     --input-bg-color: #777;
//     --border-color: #555;
//     --button-color: #46b5d1;
// }

// html {
//     width: 100vw;
//     height: 100vh;
//     overflow: hidden;
//     -webkit-background-size: cover;
//     -moz-background-size: cover;
//     -o-background-size: cover;
//     background-size: cover;
//     body {
//         margin: 0;
//         font-family: 'Open Sans', 'Droid Sans';
//         -webkit-font-smoothing: antialiased;
//         -moz-osx-font-smoothing: grayscale;
//       //  background-color: var(--main-bg-color);
//         color: var(--text-color);
//         transition: background 0.5s ease-out;
//         flex: 1;
//         height: 100vh;
//         width: 100vw;
//         max-height: 100%;
//         max-width: 100%;
//         padding: 0;
//         #root {
//             flex: 1;
//             display: flex;
//             max-height: 100vh;
//             max-width: 100vw;
//             width: 100vw;
//             height: 100vh;
//             flex-flow: column nowrap;
//             position: relative;
//         }
//         &.fontLoaded {
//             font-family: 'Open Sans', 'Frutiger Roman', 'Frutiger Bold';
//         }
//     }
// }

// // html {
// // }

// /**
// * Misc
// */

// button.btn-primary {
//     background-color: var(--button-color);
//     border-color: var(--button-color);
// }

// .contentarea {
//     min-height: 300px;
// }

// .form-control {
//     background-color: var(--input-bg-color);
// }

// /* The emerging W3C standard
//    that is currently Firefox-only */

// * {
//     scrollbar-width: thin;
//     scrollbar-color: orange black;
// }

// /* Works on Chrome/Edge/Safari */

// *::-webkit-scrollbar {
//     width: 16px;
// }

// *::-webkit-scrollbar-track {
//     background: black;
// }

// *::-webkit-scrollbar-thumb {
//     background-color: orange;
//     border-radius: 20px;
//     border: 6px solid black;
// }

// .user-activity-panel-group {
//     flex: 1;
//     display: flex;
//     flex-direction: column;
//     & > div {
//         height: 33%;
//     }
// }

// .vertical-center {
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

// .page {
//     flex: 1;
//     display: flex;
//     flex-direction: column;
//     width: 100vw;
//     height: 100vh;
//     max-width: 100vw;
//     max-height: 100vh;
//     opacity: 0.95;
//     position: fixed;
//     top: 0;
//     left: 0;
// }

// .pulse {
//     animation: pulse-animation 2s infinite;
// }

// @keyframes pulse-animation {
//     0% {
//         box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
//     }
//     100% {
//         box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
//     }
// }

// .heart {
//     color: red;
//     font-size: 50px;
//     animation: heart-pulse 0.9s infinite ease-out;
// }

// .pulse-item {
//     animation: heart-pulse 0.9s infinite ease-out;
//     border: 1px solid;
// }

// @keyframes heart-pulse {
//     0% {
//         transform: scale(0);
//         /* scaling to 0 */
//     }
//     50% {
//         transform: scale(1.2);
//         /* increasing the size */
//     }
//     70% {
//         transform: scale(0.65);
//         /* decreasing the size */
//     }
//     100% {
//         transform: scale(0);
//         /* seeting back to initial size */
//     }
// }

// .animated-zoom {
//     background-repeat: no-repeat;
//     background-position: left top;
//     padding-top: 95px;
//     margin-bottom: 60px;
//     -webkit-animation-duration: 10s;
//     animation-duration: 10s;
//     -webkit-animation-fill-mode: both;
//     animation-fill-mode: both;
// }

// @-webkit-keyframes pulse-zoom {
//     0% {
//         font-size: large;
//     }
//     50% {
//         font-size: xx-large;
//     }
//     100% {
//         font-size: large;
//     }
// }

// @keyframes pulse-zoom {
//     0% {
//         font-size: large;
//     }
//     50% {
//         font-size: xx-large;
//     }
//     100% {
//         font-size: large;
//     }
// }

// .pulse-zoom {
//     -webkit-animation: pulse-zoom 2s infinite ease-out;
//     animation: pulse-zoom 2s infinite ease-out;
// }

// .z-depth-4 {
//     -webkit-box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21) !important;
//     box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21) !important;
// }

// .stanleyRow {
//     display: flex;
//     flex-flow: row nowrap;
//     justify-content: space-between;
//     margin-right: 0px;
//     margin-left: 0px;
// }

// .stanleyCol {
//     display: flex;
//     flex-flow: column nowrap;
// }

// .dropdown-toggle::after {
//     display: inline-block;
//     width: 0;
//     height: 0;
//     margin-left: 0.255em;
//     vertical-align: 0.255em;
//     content: '';
//     border-top: 0.3em solid;
//     border-right: 0.3em solid transparent;
//     border-bottom: 0;
//     border-left: 0.3em solid transparent;
// }

// .text-nowrap {
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     overflow: hidden;
// }

// /* mixin for multiline */

// .multi-line-ellipsis-text {
//     overflow: hidden;
//     position: relative;
//     line-height: 1.2em;
//     max-height: 6em;
//     text-align: justify;
//     margin-right: -1em;
//     padding-right: 1em;
//     &:before {
//         content: '...';
//         position: absolute;
//         right: 0;
//         bottom: 0;
//     }
//     &:after {
//         content: '';
//         position: absolute;
//         right: 0;
//         width: 1em;
//         height: 1em;
//         margin-top: 0.2em;
//         background: white;
//     }
// }

// button {
//     font-family: 'Open Sans', sans-serif;
//     font-size: 14px;
//     display: inline-block;
//     height: 36px;
//     min-width: 88px;
//     padding: 6px 16px;
//     line-height: 1.42857143;
//     text-align: center;
//     white-space: nowrap;
//     vertical-align: middle;
//     -ms-touch-action: manipulation;
//     touch-action: manipulation;
//     cursor: pointer;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     border: 0;
//     border-radius: 2px;
//     background: #000;
//     color: #fff;
//     outline: 0;
//     border: 1px solid #ffdb0a;
//     border-radius: 15px;
// }

// .slideup,
// .slidedown {
//     max-height: 0;
//     overflow-y: hidden;
//     -webkit-transition: max-height 1s ease-in-out;
//     -moz-transition: max-height 1s ease-in-out;
//     -o-transition: max-height 1s ease-in-out;
//     transition: max-height 1s ease-in-out;
// }

// .slidedown {
//     max-height: initial;
// }

// .slidein,
// .slideout {
//     width: 0;
//     -webkit-transition: width 1s ease-in-out;
//     -moz-transition: width 1s ease-in-out;
//     -o-transition: width 1s ease-in-out;
//     transition: width 1s ease-in-out;
// }

// .slideout {
//     width: 0px !important;
// }

// .full-width {
//     width: calc(100%) !important;
// }
// .full-width-20 {
//     width: calc(100% - 20px) !important;
// }
// .full-width-30 {
//     width: calc(100% - 30px) !important;
// }
// .full-width-40 {
//     width: calc(100% - 40px) !important;
// }

// .text-ellipsis {
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     overflow: hidden;
// }

// .text-ellipsis-2-line {
//     text-overflow: ellipsis;
//     overflow: hidden;
//     // Addition lines for 2 line or multiline ellipsis
//     display: -webkit-box; // !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
// }

// .text-ellipsis-3-line {
//     text-overflow: ellipsis;
//     overflow: hidden;
//     // Addition lines for 3 line or multiline ellipsis
//     display: -webkit-box; //  !important;
//     -webkit-line-clamp: 3;
//     -webkit-box-orient: vertical;
//     white-space: normal;
// }

// .react-grid-layout {
//     margin-top: 10px;
// }

// .maximize-widget {
//     width: 98% !important;
//     height: 98% !important;
//     flex: 1;
// }

// .maximize-widget-width {
//     width: 98% !important;
//     flex: 1;
// }

// .maximize-widget-width-50 {
//     width: 48% !important;
//     flex: 1;
// }

// .maximize-widget-height {
//     height: 98% !important;
//     flex: 1;
// }
