$card-background-color: #243240;
$text-color-white: #ffffff;
$text-color-grey: #a0a0a0;

.x-cls-overview-card-component {
    .card-property {
        width: 560px;
        height: initial;
    }

    .card-content-property {
        background: $card-background-color;
        opacity: 1;
        height: 100%;
    }

    .flex-row-property {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .header.flex-row-property {
        padding-right: 15px;
    }

    .marginTen {
        margin-top: 10px;
    }

    .marginEight {
        margin-top: 8px;
    }

    .flex-col-property {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .align-property {
        display: flex;
        color: $text-color-white;
        text-align: center;
        font: normal normal normal 22px/30px Open Sans;
        letter-spacing: 0px;
        &.left-side {
            align-items: flex-end;
        }
        &.right-side {
            align-self: flex-end;
        }
    }

    .circular-progress-title {
        color: $text-color-white;
        font: normal normal normal 14px/19px Open Sans;
        letter-spacing: 0px;
        text-align: left;
        justify-self: flex-start;
        white-space: nowrap;
    }

    .centered-justification {
        justify-content: center;
        align-items: center;
    }

    .flexOne {
        flex: 1;
    }

    .flexOneBox {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .greyTitle {
        text-align: center;
        font: normal normal normal 22px/30px Open Sans;
        letter-spacing: 0px;
        color: $text-color-grey;
        white-space: nowrap;
    }

    .valueLabels {
        text-align: center;
        padding: 5px;
        font: normal normal normal 20px/28px Open Sans;
        letter-spacing: 0px;
        color: $text-color-white;
        white-space: nowrap;
        & > span {
            font-size: 14px;
            margin-left: -3px;
        }
    }

    .progressText {
        text-align: center;
        font: normal normal normal 22px/30px Open Sans;
        letter-spacing: 0px;
        color: $text-color-white;
    }

    .x-cls-expand-switch {
        position: absolute;
        right: 0px;
        top: -3px;
        opacity: 0; // currently hiding expand switch
    }

    .x-health-value {
        opacity: 1; // currently hiding health
        margin: 0px;
        position: absolute;
        left: 0px;
        top: 0px;
        font-size: 1.2em;
        margin-left: 10px;

        &.red {
            color: red;
        }
        &.green {
            color: green;
        }
        &.orange {
            color: orange;
        }
    }

    .x-health-panel {
        padding: 15px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-evenly;
        gap: 10px;
        background-color: #243240;

        .x-title {
            color: #fff;
            font-weight: bold;
            font-size: 18px;
        }

        .x-value {
            font-size: larger;
        }

        .x-component {
            font-size: 18px;
        }

        .x-health-change-card {
            min-width: 95%;
            .x-component {
                color: #aaa;
                white-space: nowrap;
            }
            .x-health {
                height: 96px;
                overflow-y: auto;
            }
        }

        .x-health-forecast-card {
            max-height: 200px;
            height: 200px;
            width: 240px;
            display: flex;
            flex-flow: column nowrap;
            padding-right: 2px;

            .x-forcasts {
                color: #999;
                font-size: 16px;
                padding-left: 10px;
                flex: 1 1;
                grid-gap: 5px;
                gap: 5px;
                display: flex;
                flex-flow: column nowrap;
                overflow-y: auto;

                .x-main-values {
                    display: flex;
                    flex-flow: row nowrap;
                    position: relative;

                    & > div + div {
                        opacity: 1;
                        margin-left: 25px;
                    }
                    .x-forecast-panel {
                        padding: 0px 0px 5px 0px;
                        margin-top: 10px;
                        margin-bottom: -5px;

                        &:hover {
                            cursor: pointer;
                            border: 1px ridge silver;
                        }
                    }

                    .x-name {
                        transform: rotate(270deg);
                        transform-origin: center center 0;
                        color: rgba(0, 0, 0, 0.9);
                        background-color: #ccc;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;

                        width: 115px;
                        height: 25px;
                        position: absolute;
                        top: 60px;
                        left: -52px;

                        display: flex;
                        flex-flow: column nowrap;

                        & > span {
                            flex: 1;
                        }
                    }

                    .x-stripe {
                        flex: initial;
                        height: 5px;
                        width: 100%;
                        background-color: green;
                        &.green {
                            background-color: green;
                        }
                        &.orange {
                            background-color: orange;
                        }
                        &.red {
                            background-color: rgb(133, 71, 71);
                        }
                    }
                }
            }

            .x-forecast-datum {
                display: flex;
                flex-flow: column nowrap;

                .x-label {
                    font-size: 1.1em;
                    color: aqua;
                    padding-top: 5px;
                    display: inline-block;
                }
                .x-value {
                    font-size: 1.8em;
                    line-height: 1em;
                    padding: 0;
                    align-self: center;
                    color: #999;
                }
            }
        }

        .x-health-history-card {
            max-height: 200px;
            height: 200px;
            width: 240px;
            display: flex;
            flex-flow: column nowrap;
            padding-right: 2px;

            .x-name {
                display: flex;
                justify-content: space-between;
                flex-flow: row wrap;
                padding-right: 10px;
            }

            .x-history {
                color: #999;
                font-size: 16px;
                padding-left: 10px;
                flex: 1;
                gap: 5px;

                display: flex;
                flex-flow: column nowrap;
                overflow-y: auto;
            }

            .x-cmp-summaries {
                padding-left: 10px;
                // height: 100px;
                display: flex;
                flex-flow: column nowrap;

                .x-summary {
                    display: flex;
                    flex-flow: column nowrap;
                }

                .changed,
                .replacement {
                    background-color: rgba(203, 195, 195, 0.3);
                }
                .repaired,
                .repair {
                    background-color: rgba(58, 47, 47, 0.7);
                }
            }
        }

        .x-component {
            padding-left: 15px;
            .x-name {
                padding: 0px 10px 0px 5px;
            }
        }

        & > div {
            flex: 1;
            border: 1px ridge #a0a0a0;

            // box-shadow: 5px 12px #ebebeb;
            background-color: #243240;
        }
    }
}
