/* Helper mixins and styles */

.clearfix {
    clear: both;
    &:after {
        clear: both;
        display: block;
        content: '';
    }
}
///@group Utilities
@mixin clearfix() {
    &:after {
        clear: both;
        display: block;
        content: '';
    }
}
