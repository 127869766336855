.x-cls-carbody-table-header-cells {
    opacity: 1;
}

.x-cls-carbody-table-header-cells {
    &.clickable {
        cursor: pointer;
    }
    &.highlight-studId {
        border-left: 1.5px solid rgb(255, 219, 10);
        border-right: 2px solid rgb(255, 219, 10);
        border-top: 1.5px solid rgb(255, 219, 10);
        background-color: rgba(255, 219, 10, 0.2);
    }
}