@import 'scss/main.scss';
$headerButtonColor: rgba(238, 238, 238, 0.4);
.main-navbar {
    padding: 0px;
    position: sticky;
    flex-grow: initial;
    color: $smartlync-white;
    padding-top: 4px;
    cursor: pointer;
    height: 64px;
    max-height: 60px;
}

.material-icons {
    cursor: pointer;
}

.elu-logo {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-left: 16px;
    justify-content: center;
    align-items: center;
}

.hamburger-menu {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 17px;
    margin-right: 14px;
}

.disabled {
    display: 'flex';
    flex-direction: 'column';
    height: '100%';
    justify-content: 'center';
    align-items: 'center';
    margin-left: '16px';
    pointer-events: none;
    margin-left: 16;
    user-select: none;
    opacity: 0.5;
}

.header-wrapper {
    background-color: #1a1a1a;
    padding: 4px 0 3px 0px;
    margin: 0px;
    flex: 1;
    &>div {
        display: flex;
        align-items: center;
    }
    .x-cls-loader {
        height: 4px;
    }
    .icon-wrapper {
        display: flex;
        padding-left: 16px;
        height: 49px;
    }
}

.x-cls-nav-logo {
    max-width: 215px;
}

.x-cls-page-title {
    padding-bottom: 10px;
    margin: -1px 30px 0 60px;
    flex: 1 1;
    .title {
        line-height: 2rem;
        text-align: left;
        font-size: 25px;
        font-weight: bold;
        letter-spacing: 0px;
        color: $smartlync-primary-yellow;
        opacity: 1;
        white-space: nowrap;
    }
    &.search-expanded {
        width: initial;
        max-width: initial;
        flex: initial;
    }
}

.x-cls-user-actions {
    width: 250px;
    min-width: initial;
    max-width: initial;
    flex: initial;
    .x-cls-user-menu {
        opacity: 1;
        height: 45px;
    }
}

.x-cls-language-switcher.x-cls-nav-language-switcher {
    background-color: transparent;
    height: initial;
    position: initial;
    top: initial;
    right: initial;
    z-index: initial;
}

.x-cls-app-bar {
    .grid-item {
        display: flex;
        height: 100%;
        align-items: center;
    }
    .each-breadcrum {
        // background-color: #00000080;
        border-radius: 100px;
        color: #ffffff8f;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        // width: 86px;
        display: flex;
        flex-direction: row;
        height: 100%;
        margin-left: 15px;
        justify-content: center;
        align-items: center;
        // width: 200px;
    }
    .location-breadcrum {
        width: auto;
        color: #fff;
        background: #ffffff29;
        border-radius: 100px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        flex-direction: row;
        height: 30px;
        margin-left: 5px;
        justify-content: center;
        align-items: center;
        width: 220px;
        svg {
            color: #ffffff;
            font-size: 20px;
        }
        .location-label {
            // width: 170px;
            display: flex;
            .loader {
                width: 170px;
                margin: 10px auto;
            }
        }
    }
    .breadcrumb-grid-location {
        max-width: 62%;
        display: flex;
        align-items: center;
        margin: 5px;
    }
}

.hide {
    display: none;
}