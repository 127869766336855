.x-cls-ta-table-header-cell {
    position: sticky;
    top: 0;
    display: table-cell;
    background-color: #333;
    border: 1.0px solid #525252;
    &>div {
        padding-left: 0px;
        padding-right: 0px;
    }
    .sort-label {
        padding: 0;
        margin: 0;
        flex: initial !important;
        font-size: 12px;
        color: #fff;
        flex: 1;
        justify-self: center;
        justify-content: space-around;
        align-items: center;
        &>* {
            margin: 0px;
        }
    }
    .column-label {
        font-size: 14px;
        color: #fff;
        display: flex;
        flex: 1;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
    }
    .sort-icon {
        &.hide {
            color: 'red';
        }
        &.show {
            color: 'blue';
        }
    }
    &.highLightFilter {
        border-left: 2px solid;
        border-right: 2px solid;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-color: greenyellow;
    }
    .label-style {
        font-size: 13px;
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}