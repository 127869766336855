.x-cls-risk-table-body-cell {
    display: flex;
    &>div {
        flex: 1;
    }
    .clear-cell {
        flex: 1;
        justify-content: center;
        align-items: center;
        svg {
            width: 40px;
            height: 40px;
            position: absolute;
            top: -2px;
            left: -3px;
        }
    }
    &.highlight-studId {
        border-top: 1.5px solid rgb(255, 219, 10);
        border-bottom: 1.5px solid rgb(255, 219, 10);
        background-color: rgba(255, 219, 10, 0.2);
    }
    &.highlight-studId-col {
        border-left: 1.5px solid rgb(255, 219, 10);
        border-right: 1.5px solid rgb(255, 219, 10);
        background-color: rgba(255, 219, 10, 0.2);
    }
    .x-overall-risk-item {
        flex: 1;
    }
}