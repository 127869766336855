.x-cls-event-rate-per-event-widget {
    opacity: 1;

    &.react-grid-item {
        // default widget background and border
        background-color: #333;
        border: 1px solid #bbb;
    }
}

.x-cls-event-rate-per-event-widget-header {
    opacity: 1;
}

.x-cls-event-rate-per-event-widget-body {
    opacity: 1;
}
