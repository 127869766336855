.x-cls-carbodyid-column-cell {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.4px;

    /* secondary/main */

    color: #c3d4fb;
}
