.css-b4j20-MuiDateRangePickerDay-root{
    background-color: transparent !important;
}
.css-oryg2s-MuiDateRangePickerDay-root:last-of-type {
    border-radius: 0 !important;
}
.css-n0vfwh-MuiDateRangePickerDay-root:last-of-type {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.css-n0vfwh-MuiDateRangePickerDay-root:first-of-type {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.css-8d2zhb-MuiDateRangePickerDay-root:first-of-type {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.css-ay30ms-MuiDateRangePickerDay-root:last-of-type {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd{
    border-radius: 0 !important;
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}