.avatar__absolute{
    position: absolute !important;
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
    font-size: 11px !important;
    color: #fff !important;
    width: 20px !important;
    height: 20px !important;
    background-color: rgb(66, 65, 65) !important;
    border: .5px solid gray;
}
.css-1n2mv2k{
    padding: 0 34px !important;
}