.x-cls-topFaultCount-table-header-cell {
    position: sticky;
    top: 0;
    display: table-cell;
    background-color: #333;
    height: 150px;
    &>div {
        padding-left: 0px;
        padding-right: 0px;
    }
    .sort-label {
        padding: 0;
        margin: 0;
        flex: initial !important;
        font-size: 12px;
        color: #fff;
        flex: 1;
        justify-self: center;
        justify-content: space-around;
        align-items: center;
        &>* {
            margin: 0px;
        }
    }
    .column-label {
        font-size: 14px;
        color: #fff;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
    }
    .sort-icon {
        &.hide {
            color: 'red';
        }
        &.show {
            color: 'blue';
        }
    }
}

.description-text {
    height: 250px;
    width: 250px;
    color: white;
    font-size: 12px;
    text-align: left;
    transform: rotate(-90deg);
    margin-right: -180px;
    padding-top: 15px;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}