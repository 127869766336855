@import 'scss/main.scss';
$card-background-color: #243240;
$text-color-white: #ffffff;
$text-color-grey: #a0a0a0;
$base-color: #ffdb0a;
$border-dark: rgba($base-color, 0.88);
$basic-white: #ffffff;
$basic-black: #000000;
$project-header: #243240;
.x-cls-ta-analysis-widget-table {
    .react-grid-item {
        // default widget background and border
        background-color: #333;
        border: 1px solid #bbb;
    }
    .x-cls-car-body-table-widget {
        opacity: 1;
    }
    .x-cls-car-body-table-widget-header {
        opacity: 1;
    }
    .x-cls-car-body-table-widget-body {
        opacity: 1;
    }
    .flexOne {
        flex: 1;
    }
    td.carBody-data-cell {
        border: 1px solid #525252;
        background-color: $basic-black;
        min-width: 80px;
        // max-width: 80px;
        text-align: center;
        cursor: pointer;
        &.carBody-data {
            background-color: rgb(243, 241, 233);
        }
    }
    th.carBody-data-cell-header {
        &.carBodyHeader-data {
            background-color: #222;
        }
    }
    .react-grid-item {
        // default widget background and border
        background-color: #333;
        border: 1px solid #bbb;
    }
    .firstColumnStyle {
        &.highLightFilter {
            border-left: 1px solid;
            border-right: 1px solid;
            border-top: 2px solid;
            border-bottom: 2px solid;
            border-color: greenyellow;
        }
    }
}