.x-cls-signal-chart {
    display: flex;
    flex-flow: column nowrap;
    background: #212121;
    padding: 20px;
    .highcharts-loading {
        background-color: transparent !important;
        .highcharts-loading-inner {
            background-color: transparent !important;
            color: purple;
        }
    }
    .disabled {
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: #ffffff4d;
    }
    .disabledSignal {
        background-color: #ffffff29;
        border-radius: 16px;
    }

    .x-title-bar {
        .x-title-label {
            float: left;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 160%;
            display: flex;
            align-items: center;
            letter-spacing: 0.15px;
            color: #ffffff;
        }
        .x-show-btn-grp {
            display: flex;
            flex-flow: row;
            float: right;
            align-items: center;
            .x-label {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 150%;
                display: flex;
                align-items: center;
                letter-spacing: 0.15px;
                color: #ffffff;
            }
            .x-show-signal-type {
                button {
                    // background: #2196f3;
                    border-radius: 16px;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin: 0px 7px;
                    padding: 3px 10px;
                    height: 24px;
                    width: 98px;
                    span {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.16px;
                        color: rgba(0, 0, 0, 0.87);
                    }
                    &.VOLTAGE {
                        border: 1px solid #2196f3;

                        span {
                            color: #2196f3;
                        }
                    }
                    &.CURRENT {
                        border: 1px solid #f48fb1;

                        span {
                            color: #f48fb1;
                        }
                    }
                    &.LIFT_POSITION {
                        border: 1px solid #66bb6a;
                        width: 128px;

                        span {
                            color: #66bb6a;
                        }
                        background: #212121;
                    }
                    svg {
                        width: 0.7em;
                        height: 0.7em;
                    }
                }
            }
            .x-show-wip-wop-btn-grp {
                button {
                    border: 1px solid rgba(195, 212, 251, 0.5);
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 22px;
                    letter-spacing: 0.46px;
                    text-transform: uppercase;
                    min-width: initial;
                    width: 60px;
                    span {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 22px;
                        letter-spacing: 0.46px;
                        text-transform: uppercase;
                        color: #c3d4fb;
                    }
                }
            }
        }
    }
    .no-data-message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &-text {
            font-size: 20px;
            display: flex;
            justify-content: center;
            font-size: 16px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 160%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.15px;
        }
        &-sub {
            display: flex;
            justify-content: center;
            font-size: 16px;
            font-family: 'Roboto';
            font-style: normal;
            line-height: 160%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.15px;
        }
        display: flex;
        flex-direction: column;
        .no-data-img {
            height: 188px;
            left: 796.67px;
        }
    }
}
