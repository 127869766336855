@import 'scss/main.scss';

.x-cls-devices-fault-report-widget {
    opacity: 1;
    display: flex;
    flex: 1;

    & > div {
        flex: 1;
    }

    .x-cls-loading {
        align-items: center;
        justify-content: center;
        display: flex;
        &,
        & > div {
            color: #fff;
            font-size: 14px;
            opacity: 1;
        }
    }

    .x-cls-devices-fault-report-widget-table {
        width: 100%;
        height: 100%;
        max-width: 100%;
        display: flex;

        .cell-content.fault-description {
            font-size: 0.875rem;
            text-align: left;
            font-family: Open Sans;
        }

        th {
            border: 1px solid #525252;
        }

        td {
            background-color: #000000;
            border-bottom: #000000;
            border: 1px solid #525252;

            .threshold-met {
                background-color: #e42d37;
            }
        }
        td.x-cls-footer-cell {
            background-color: #182129 !important;
            &:last-child {
                position: static;
                right: 0px;
            }
        }
        tr.x-cls-footer-row {
            td + &:last-child {
                position: static;
                right: 0px;
            }
        }
    }

    &.react-grid-item {
        // default widget background and border
        background-color: #333;
        border: 1px solid #bbb;
    }
}

.x-cls-devices-fault-report-widget-header {
    opacity: 1;
}

.x-cls-devices-fault-report-widget-body {
    opacity: 1;
}
